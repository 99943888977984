<template>
  <company-contacts
    title="Contact"
    :currentCompanyID="companyID"
    :tableColumns="tableColumns"
    addNewRecordTitle="Add New Company Contact"
    editRecordTitle="Edit Company Contact for - "
    deleteRecordTitle="Delete Company Contact"
    :showCompanyId="false"
    from="company-contacts"
  />
</template>
<script>
import CompanyContacts from "../../../components/App/Company/CompanyContacts.component.vue";
export default {
  props: ["companyID"],
  data() {
    return {
      tableColumns: [
        { cell: "myTemplate", title: "Actions", width: "120" },
        {
          field: "firstName",
          title: "First Name",
          width: "120",
        },
        {
          field: "lastName",
          title: "Last Name",
          width: "120",
        },
        {
          field: "contactTypeDescriptions",
          title: "Contact Type",
          cell: "contactTypeDescriptionsTemplete",
          width: "170",
        },
        {
          field: "phoneNumbers",
          title: "Phone Numbers",
          cell: "phoneNumbersTemplate",
          width: "150",
        },
        {
          field: "emailAddress",
          title: "Email Address",
          width: "160",
        },
        {
          field: "notes",
          title: "Notes",
          cell: "notesTemplate",
          width: "80",
        },
      ],
    };
  },
  components: {
    CompanyContacts,
  },
  // methods: {
  //   editRecord(dataItem) {
  //     this.$refs.CompanyContactsModal.show({
  //       title: `Edit Company Contact for - ${dataItem.contactID} `,
  //       contactID: dataItem.contactID,
  //       companyID: this.companyID,
  //       exitFunction: async () => {
  //         this.getTableData();
  //       },
  //     });
  //   },
  // },
};
</script>
